<template>
  <section class="slider-logo">
    <div
      v-if="title || description"
      class="slider-logo__text ui-container"
    >
      <h2
        v-if="title"
        class="slider-logo__title h h--2 ui-container"
        v-html="title"
      />

      <span
        v-if="description"
        class="slider-logo__description t t--3"
        v-html="description"
      />
    </div>

    <div class="slider-logo__marquee-wrapper">
      <Vue3Marquee
        :duration="sliderDuration"
        pause-on-hover
        clone
      >
        <template
          v-for="(item, i) in items"
          :key="i"
        >
          <UiBaseLink
            v-if="item.slug"
            :link="`/vendor/${item.slug}/`"
            class="slider-logo__item slider-logo__link"
          >
            <img
              width="54"
              height="54"
              :src="item.logo_path || ''"
              alt="Логотип университета"
              class="slider-logo__picture"
            >
          </UiBaseLink>

          <img
            v-else
            width="54"
            height="54"
            :src="item.logo_path || ''"
            alt="Логотип университета"
            class="slider-logo__item slider-logo__picture"
          >
        </template>
      </Vue3Marquee>
    </div>
  </section>
</template>

<script setup lang="ts">
  import { Vue3Marquee } from 'vue3-marquee'

  import UiBaseLink from '@academica-box/components/Links/UiBaseLink/UiBaseLink.vue'
  import type { PartnersCarouselItem } from '@/api/partners'

  const props = defineProps<{
    title?: string;
    description?: string;
    items: PartnersCarouselItem[]
  }>()

  const viewport = useViewport()
  const mdMax = computed(() => viewport.isLessThan('lg'))

  const sliderDuration = computed(() => {
    return mdMax ? 3.5 * props.items.length : 7 * props.items.length
  })
</script>

<style lang="scss">
  @use '@academica-box/utils/styles/mixins/UiMedia' as *;
  @use '@academica-box/utils/styles/mixins/UiHover' as *;

  .slider-logo__marquee-wrapper {
    min-height: 54px;

    @include media(lg) {
      min-height: 50px;
    }

    @include media(xl) {
      min-height: 56px;
    }

    @include media(1600) {
      min-height: 70px;
    }
  }

  .slider-logo__title {
    margin-bottom: 32px;
  }

  .slider-logo__text {
    margin-bottom: 24px;

    @include media(lg) {
      margin-bottom: 32px;
    }
  }

  .slider-logo__description {
    color: var(--text-addition-color);
  }

  .slider-logo__item {
    display: flex;
    align-items: center;
    margin-right: 72px;
    padding: 0 24px;

    @include media(lg, lg-max) {
      margin-right: 67px;
    }

    @include media(xxl) {
      margin-right: 130px;
    }

    @include media(1600) {
      margin-right: 200px;
    }
  }

  .slider-logo__link {
    border-radius: 10px;
    cursor: pointer;

    @include hover {
      background-color: var(--bg-secondary-color);
    }
  }

  .slider-logo__picture {
    width: auto;
    height: 54px;

    @include media(lg) {
      height: 50px;
    }

    @include media(xl) {
      height: 56px ;
    }

    @include media(1600) {
      height: 70px;
    }
  }
</style>
